/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Layout } from '@leshen/gatsby-theme-contentful'

import {
  Billboard,
  SplitContent,
  Image,
  Price,
  Brandy,
  List,
  ListItem,
  AvailabilityForm,
  LinkButton,
  Banner,
  VariableContent,
  Stack,
  Columns,
  Column,
  LeshenPhoneCTA,
  SVGImage,
} from '@leshen/gatsby-theme-leshen'

import { Typography, Dropdown } from '@leshen/ui'
import { graphql, Link as GatsbyLink, navigate } from 'gatsby'

import SeoCtaBar from '../components/SeoCtaBar'
import getFullStateName from '../utils/getFullStateName'

const StateTemplate = ({ data }) => {
  const { Page_Path, State, Promo_Code, Meta_Description, Meta_Title } =
    data.pageInfo.edges[0].node
  const State_Name = getFullStateName(State)

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  const stateHeroImageData = {
    ...data.stateHeroImage?.cloudinary[0]?.gatsbyImageData,
    context: data.stateHeroImage?.cloudinary[0]?.context,
  }

  const stateHeroMobileImageData = {
    ...data.stateHeroMobileImage?.cloudinary[0]?.gatsbyImageData,
    context: data.stateHeroMobileImage?.cloudinary[0]?.context,
  }

  const womanLaughingImageData = {
    ...data.womanLaughingImage?.cloudinary[0]?.gatsbyImageData,
    context: data.womanLaughingImage?.cloudinary[0]?.context,
  }

  const womanLaughingMobileImageData = {
    ...data.womanLaughingMobileImage?.cloudinary[0]?.gatsbyImageData,
    context: data.womanLaughingMobileImage?.cloudinary[0]?.context,
  }

  const availabilityMapImageData = {
    ...data.availabilityMapImage?.cloudinary[0]?.gatsbyImageData,
    context: data.availabilityMapImage?.cloudinary[0]?.context,
  }

  const availabilityMapMobileImageData = {
    ...data.availabilityMapMobileImage?.cloudinary[0]?.gatsbyImageData,
    context: data.availabilityMapMobileImage?.cloudinary[0]?.context,
  }

  const cityDropdownData = data.cities.edges.map((current) => ({
    label: current.node.City,
    value: current.node.Page_Path,
  }))

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Billboard
            customLink={GatsbyLink}
            variant="full"
            mainContent={
              <>
                <Typography variant="h1">
                  CenturyLink<sup>®</sup> availability in {State_Name}
                </Typography>
                <Typography variant="h4">
                  Check your ZIP Code for CenturyLink internet rates starting at
                </Typography>
                <Price
                  standalone
                  className="price embedded-entry"
                  {...data.brandyPackage?.price}
                  bullets={data.brandyPackage.bullets}
                  variant="Default"
                />
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">Unlimited data</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">No annual contract</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">24/7 tech support</Typography>
                    </Typography>
                  </ListItem>
                </List>
                <AvailabilityForm
                  placeholderText="Enter ZIP"
                  buttonText="See Your Plan"
                  buttonColor="primary"
                />
              </>
            }
            image={<Image data={stateHeroImageData} isImageCritical />}
            mobileImage={
              <Image data={stateHeroMobileImageData} isImageCritical />
            }
          />
          <SeoCtaBar />
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="Light"
            image={<Image data={womanLaughingImageData} isImageCritical />}
            mobileImage={
              <Image data={womanLaughingMobileImageData} isImageCritical />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Why CenturyLink in {State_Name}
                </Typography>
                <Typography variant="h4">
                  High-speed internet service in your corner.
                </Typography>
                <Typography>
                  As a high-speed internet provider with plans up to 940 Mbps,
                  CenturyLink connects families in {State_Name} to endless
                  possibilities online—all while considering your budget,
                  lifestyle, and support needs.
                </Typography>
                <Brandy
                  symbol={data.disclaimerOne.symbol}
                  text={data.disclaimerOne.text}
                  variant="legal"
                />
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        <strong>No annual contract</strong> or cancellation fees
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        <strong>Unlimited data</strong> and no overage charges
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        <strong>Home WiFi availability</strong> with CenturyLink
                        modem rental or 3rd-party device
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        <strong>24/7 technical support</strong> via chat
                      </Typography>
                    </Typography>
                  </ListItem>
                </List>
                <br />
                <br />
                <LinkButton to="/cart" CustomLink={GatsbyLink} color="primary">
                  Start Your Order
                </LinkButton>
              </>
            }
          />
          <Banner
            className="background-color-secondary"
            heading="Already a CenturyLink Customer? Call to see if you qualify for faster speeds today."
            backgroundColor="secondary"
          />
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="Light"
            image={<Image data={availabilityMapImageData} isImageCritical />}
            mobileImage={
              <Image data={availabilityMapMobileImageData} isImageCritical />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  CenturyLink fiber availability is growing in {State_Name}
                </Typography>
                <Typography>
                  Find out if your city is available in the CenturyLink fiber
                  internet network.
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  CenturyLink bundles and 3rd-party TV
                </Typography>
                <Typography>
                  Boredom can’t win against the powers of CenturyLink internet,
                  home phone, and 3rd-party TV combined.
                </Typography>
              </>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl">
              <Columns leftAligned={false}>
                <Column
                  borderColor="primary"
                  borderType="topbar"
                  image={
                    <SVGImage
                      svg={data.cardsOne.cloudinary[0].svg.code}
                      presentationWidth={
                        data.cardsOne.cloudinary[0].svg.presentationWidth
                      }
                    />
                  }
                >
                  <Typography variant="h4">Internet + 3rd-party TV</Typography>
                  <List>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          <strong>Reliable internet speeds</strong>
                        </Typography>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          <strong>
                            Hundreds of live TV channels from CenturyLink
                            partners
                          </strong>
                        </Typography>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          <strong>
                            Work and play using CenturyLink internet speeds
                          </strong>
                        </Typography>
                      </Typography>
                    </ListItem>
                  </List>
                  <br />
                  <br />
                  <br />
                  <LeshenPhoneCTA color="primary">Order Now</LeshenPhoneCTA>
                </Column>
                <Column
                  borderColor="primary"
                  borderType="topbar"
                  image={
                    <SVGImage
                      svg={data.cardsTwo.cloudinary[0].svg.code}
                      presentationWidth={
                        data.cardsTwo.cloudinary[0].svg.presentationWidth
                      }
                    />
                  }
                >
                  <Typography variant="h4">Internet + home phone</Typography>
                  <List>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          <strong>$10 savings built into rate</strong>
                        </Typography>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          <strong>Reliable internet speeds</strong>
                        </Typography>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          <strong>
                            CenturyLink home phone with Unlimited Nationwide
                            Calling
                          </strong>
                        </Typography>
                      </Typography>
                    </ListItem>
                  </List>
                  <br />
                  <br />
                  <br />
                  <LeshenPhoneCTA color="primary">Order Now</LeshenPhoneCTA>
                </Column>
                <Column
                  borderColor="primary"
                  borderType="topbar"
                  image={
                    <SVGImage
                      svg={data.cardsThree.cloudinary[0].svg.code}
                      presentationWidth={
                        data.cardsThree.cloudinary[0].svg.presentationWidth
                      }
                    />
                  }
                >
                  <Typography variant="h4">
                    Internet + home phone + 3rd-party TV
                  </Typography>
                  <List>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          <strong>Reliable internet speeds</strong>
                        </Typography>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          <strong>
                            CenturyLink home phone with Unlimited Nationwide
                            Calling
                          </strong>
                        </Typography>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="h5" rendersAs="span">
                        <Typography variant="p">
                          <strong>
                            Hundreds of live TV channels from CenturyLink
                            partners
                          </strong>
                        </Typography>
                      </Typography>
                    </ListItem>
                  </List>
                  <br />
                  <br />
                  <br />
                  <LeshenPhoneCTA color="primary">Order Now</LeshenPhoneCTA>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
        </>
      }
    />
  )
}

StateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery(
    $pagePath: String
    $stateHeroImage: String
    $stateHeroMobileImage: String
    $stateAbbreviation: String
  ) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerGetcenturylinkAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          Page_Path
          State
          Promo_Code
          Meta_Description
          Meta_Title
        }
      }
    }
    cities: allDatasetManagerGetcenturylinkAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    disclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-proximity-speed" }
    ) {
      id
      text
      symbol
    }
    stateHeroImage: contentfulMedia(identifier: { eq: $stateHeroImage }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    stateHeroMobileImage: contentfulMedia(
      identifier: { eq: $stateHeroMobileImage }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    brandyPackage(brandy_id: { eq: "ctl-copper100" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    womanLaughingImage: contentfulMedia(
      contentful_id: { eq: "3odmAC2gvbzRhjUyNQhsav" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    womanLaughingMobileImage: contentfulMedia(
      contentful_id: { eq: "4vLeoSbxmAeEuYeR0aVQBo" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    availabilityMapImage: contentfulMedia(
      contentful_id: { eq: "4Zee0Z8ajci7U01TizfRRJ" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    availabilityMapMobileImage: contentfulMedia(
      contentful_id: { eq: "21jWEeIUTCzWuCzJG0RUYV" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    cardsOne: contentfulMedia(contentful_id: { eq: "7qrE2jNHd9vj2x8WXejKtF" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsTwo: contentfulMedia(contentful_id: { eq: "4QHN8S6Ye89HXY787fq7rW" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsThree: contentfulMedia(
      contentful_id: { eq: "6VQ8x5ZSUkqbgV5GreWVtQ" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    footerDisclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-allrightsreserved" }
    ) {
      symbol
      text
    }
    footerDisclaimerTwo: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-dtvcopyright" }
    ) {
      symbol
      text
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "dbfdc774-077c-5806-b609-4031e7f39852" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "80461f1a-e95e-5f0c-8476-cff3cdb7fe99" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
