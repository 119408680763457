import React from 'react'
import { CtaBar, LinkButton, LeshenPhoneCTA } from '@leshen/gatsby-theme-leshen'

import ShoppingCart from '../../static/images/shopping-cart.svg'

const SeoCtaBar = () => {
  return (
    <CtaBar
      backgroundColor="primary"
      sticky
      leftBlock={<LeshenPhoneCTA color="white">Call</LeshenPhoneCTA>}
      middleBlock={
        <LinkButton
          color="white"
          leftIcon={<img src={ShoppingCart} alt="shopping cart icon" />}
          to="/cart"
        >
          Order
        </LinkButton>
      }
    />
  )
}

export default SeoCtaBar
